import { request } from "@/request/http";

export default {
  getCategoryList: (data) => {
    return request("GET", `/index.php/center/getCategoryList`, data);
  },

  updatePagesExec: (data) => {
    return request("POST", `/index.php/center/updatePagesExec`, data);
  },

  getPagesInfo: (data) => {
    return request("GET", `/index.php/center/getPagesInfo`, data);
  },
}
