<template>
    <!-- 文章修改页 -->
    <div class="pdf">
        <!-- 标题 -->
        <div>
            <p class="p-padd">标题</p>
            <el-input
                v-model="title"
                placeholder="请输入内容"
                class="input-width"
            ></el-input>
        </div>
        <br />
        <!-- 分类select -->
        <div>
            <p class="p-padd">分类</p>
            <el-select
                v-model="folderid"
                placeholder="请选择"
                class="input-width"
            >
                <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
            </el-select>
        </div>
        <br />
        <!-- 正文 -->
        <div class="editor-container">
            <!-- ===================  editor组件 start  ==================== -->
            <p class="p-padd"><b>正&nbsp;&nbsp;&nbsp;&nbsp;文</b></p>
            <!-- <vue-ueditor-wrap :config="myConfig" v-model="defaultMsg" v-if="ueState"></vue-ueditor-wrap> -->
            <vue-ueditor-wrap
                v-if="ueState"
                v-model="defaultMsg"
                :config="myConfig"
                @ready="ready"
            ></vue-ueditor-wrap>
            <!-- ===================  editor组件 end    ==================== -->
        </div>
        <br />
        <div style="padding-top:30px">
            <el-button @click="goBack()">返&nbsp;&nbsp;回</el-button>
            <el-button type="primary" @click="execUpdateArticle()"
                >保&nbsp;&nbsp;存</el-button
            >
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import "@/assets/css/teacher/center/webPageUpdate.scss";
</style>

<script>
import webPageUpdate from "@/assets/js/teacher/center/webPageUpdate.js";
export default {
    ...webPageUpdate
};
</script>
